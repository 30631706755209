<template>
    <div class="SparePartList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="sparePartForm" ref="sparePartForm">
                    <el-form-item label="名称">
                        <el-input v-model="sparePartForm.spareName" placeholder="请输入名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="规格型号">
                        <el-input v-model="sparePartForm.spareType" placeholder="请输入规格型号查询"></el-input>
                    </el-form-item>
                    <el-form-item label="一级分类">
                        <el-select placeholder="请选择一级分类查询" v-model="sparePartForm.superClassCode">
                            <el-option v-for="(item, index) in oneClassList" :key="index" :label="item.subClassDesc"
                                :value="item.subClassCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                   
                </el-form>
                <div class="clearfix">
                    <el-button  class="btn" @click="$router.push({ name: 'spareLedger-create' })"
                        icon="el-icon-circle-plus-outline">新增</el-button>
                    <el-button  type="primary" @click="handleDown" icon="el-icon-upload2">导出</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="sparepartList" border style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="spareName" label="名称" align="center"> </el-table-column>
                <el-table-column prop="superClassName" label="一级分类" align="center"  width="100px">
                </el-table-column>
                <!-- <el-table-column prop="subClassName" label="二级分类" align="center" width="160">
                </el-table-column> -->
                <el-table-column prop="spareType" label="规格型号" align="center" width="80px">
                </el-table-column>
                <el-table-column prop="unit" label="单位" align="center">
                </el-table-column>
                <el-table-column prop="number" label="数量" align="center">
                </el-table-column>
                <el-table-column prop="price" label="单价(元)" align="center">
                </el-table-column>
                <el-table-column prop="totalMoney" label="总价(元)" align="center">
                </el-table-column>
                <el-table-column prop="producedDate" label="出厂日期" align="center" width="100">
                </el-table-column>
                <el-table-column prop="expirationDate" label="保质期/有限期(年）" align="center">
                </el-table-column>
                <!-- <el-table-column prop="spareImg_text" label="选型照片" align="center" width="150px">
                    <template slot-scope="scope">
                        <div v-if="scope.row.spareImg != null && scope.row.spareImg != ''">
                            <div class="demo-image__preview">
                                <el-image style="width: 100px; height: 100px" :src="scope.row.spareImg_text"
                                    :preview-src-list="scope.row.spareImgList">
                                </el-image>
                            </div>

                        </div>
                    </template>

                </el-table-column> -->
                <el-table-column prop="status_text" label="状态" align="center">
                </el-table-column>
                <el-table-column prop="whCodeName" label="所在库房" align="center">
                </el-table-column>
                <el-table-column prop="location" label="存放位置" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button type="text" size="mini" title="删除" class="delColor"
                            @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" small 
                :current-page="sparePartForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="sparePartForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>

<script>

import { searchSparePart, deleteSparePart } from '@/api/sparepart.js'
import { querySpareOneClass } from '@/api/spareclass.js'
import { downloadExcel } from '@/api/download.js'
export default {
    data() {
        return {
            sparepartList: [],
            oneClassList: [],
            sparePartForm: {
                spareName: '',
                spareType: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            path: process.env.VUE_APP_URL + this.UploadPath
        }
    },
    mounted() {
        this.loadSelect()
        this.searchSparePart()
    },
    methods: {

        handleSizeChange(val) {
            this.sparePartForm.pageSize = val;
            this.sparePartForm.pageNum = 1;
            this.searchSparePart();
        },
        handleCurrentChange(val) {
            this.sparePartForm.pageNum = val;
            this.searchSparePart();
        },

        async loadSelect() {
            await querySpareOneClass().then(res => {
                if (res.code === '000000') {
                    this.oneClassList = res.t
                }
            })
        },

        //查询
        searchSparePart() {
            searchSparePart(this.sparePartForm).then(res => {
                if (res.code === '000000') {
                    this.sparepartList = res.t.list
                    this.total = res.t.total
                    var count = 1;
                    this.sparepartList.forEach((item) => {
                        item.seq = count++
                        item.status === 0 ? (item.status_text = '充足') : (item.status_text = '待补充')
                        const listImg = item.spareImg.split(";")
                        var imgs = []
                        listImg.forEach(item2 => {
                            if (item2 != null && item2 != '') {
                                imgs.push(this.path + item2)
                            }
                        })
                        item.spareImg_text = this.path + item.spareImg.split(";")[0]
                        item.spareImgList = imgs

                    })

                    console.log(this.sparepartList)
                }

            })

        },

        handleEdit({ id }) {
            this.$router.push({
                name: 'spareLedger-edit',
                params: {
                    id
                }
            })
        },
        handleDetail({ id }) {
            this.$router.push({
                name: 'spareLedger-detail',
                params: {
                    id
                }
            })
        },

        //删除
        del({id,spareName}) {
            this.$confirm('是否删除名称为“'+spareName+'”的备品备件？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteSparePart(id).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('删除成功')
                        // this.loadSelect()
                        this.searchSparePart()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },

        //搜索
        handleSearch() {
            this.searchSparePart(this.sparePartForm);
        },
        //重置
        handleReset() {
            this.sparePartForm = {};
            this.searchSparePart(this.sparePartForm);
        },

         //导出
         handleDown() {
            this.$fullScreenLoading.show("导出中...");
            
            downloadExcel(this.sparePartForm,'/spare/downloadExcel').then(res => {
                setTimeout(() => {
                    let blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });
                    // 创建一个a标签
                    var tempLink = document.createElement("a");
                    const fileNames = res.headers['content-disposition']
                    console.log(fileNames)
                    const regFileNames = fileNames.match(/=(.*)$/)[1]
                    console.log(regFileNames)
                    // let filename = "自定义文件名称"+ '.xls'
                    // 将blob对象转为一个URL
                    var blobURL = window.URL.createObjectURL(blob);

                    // 隐藏a标签
                    tempLink.style.display = "none";
                    // 设置a标签的href属性为blob对象转化的URL
                    tempLink.href = blobURL;
                    tempLink.download = decodeURI(regFileNames)// 下载后文件名
                    // 将a标签添加到body当中
                    document.body.appendChild(tempLink);
                    // 启动下载
                    tempLink.click();
                    // 下载完毕删除a标签
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                    this.$fullScreenLoading.hide();
                    this.$message({
                    message: "导出成功~",
                    type: "success",
                });
                }, 500)
                

            })
        },
    }
}
</script>

<style lang="scss" scoped>
.SparePartList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>